import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { BillingCycleMaintenanceConstant } from "./BillingCycleMaintenanceConstant";

const SCREEN_CONSTANT = BillingCycleMaintenanceConstant;
let dateFieldList:string[] = [];
let dateTimeFieldList:string[] = [];
export const INITIAL_BILLING_CYCLE_COL_DEF: any[] = [
        {
            headerName: SCREEN_CONSTANT.BILL_CYCLE,
            field: "billingCycle",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 150,
            //pinned: "left",
            //checkboxSelection: true,
            //headerCheckboxSelection: true,
            
        },
        {
            headerName: SCREEN_CONSTANT.BILL_CYCLE_CODE,
            field: "billingCycleCode",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
        },
        {
            headerName: SCREEN_CONSTANT.FROM_DATE,
            field: "fromDate",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 130,
            dataType : "date",
        },
        {
            headerName: SCREEN_CONSTANT.TO_DATE,
            field: "toDate",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 130,
            dataType : "date",
        },
        {
            headerName: SCREEN_CONSTANT.ACTIVE_IND,
            field: "activeInd",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 130,
        },
].map((col, index) => {
    const cellRenderers:{[key:string]:((params:{[key:string]:string}, fieldName:string) => {})} = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data:any[]) => {
    const externalFnctions:{[key:string]:((fieldName:string, row:any) => {})} = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}
